import {Cart} from "../../../Cart";
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {selectVendor} from "../../../../vendor/vendorSlice";
import {selectUser} from "../../../../user/userSlice";
import {canUserOrder} from "../../../../menu/menuService";
import {useNavigate} from "react-router-dom";
import {FormButton} from "../../../../theme/styled-components/Form.styled";
import {Translate} from "../../../../intl/translator";
import {isDeliveryAvailable} from "../../../cartService";
import {selectCartType} from "../../../cartSlice";
import {useEffect, useState} from "react";
import {CartType} from "../../../cartInterfaces";
import {LightboxOptions, setOpenLightbox} from "../../../../core/panelsSlice";
import {CartMinOrderChargeDialog} from "./CartMinOrderChargeDialog";

interface Props{
    cart: Cart
}
export function CartCheckoutButton({cart}:Props) {
    const strings = {
        s_checkout: Translate('checkout'),
    };
    const dispatch = useAppDispatch();

    const vendor = useAppSelector(selectVendor);
    const user = useAppSelector(selectUser);
    const type = useAppSelector(selectCartType);
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [shopOpen, setShopOpen] = useState(false);

    useEffect(() => {
        // console.log("status: "+vendor.IsOpen);
        setShopOpen(isDeliveryAvailable(vendor));
        let disable = false;
        disable = !isDeliveryAvailable(vendor) ;
        if(type === CartType.Delivery && !isDeliveryAvailable(vendor)){
            disable = true;
        }
        setDisabled(disable);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[vendor, type])


    const checkout = () => {
        if(disabled){
            return;
        }
        if(cart.calcDeliveryMinOrderFee() > 0){
            dispatch(setOpenLightbox(LightboxOptions.CONFIRM_MIN_CHARGE));
            return;
        }
        if (
            !canUserOrder({user, vendor})
        ) {
            localStorage.setItem("intent", "/checkout");
            navigate("/login")
        } else {
            navigate("/checkout")
        }
    }

    const label = () => {
        if(!shopOpen){
            return 'Closed'
        }
        if(type === CartType.Delivery && !isDeliveryAvailable(vendor)){
            return 'Delivery is closed'
        }
        return strings.s_checkout
    }

    return (
        <>
            {cart.Items.length > 0 &&
                <div className="indent pb-3">
                    <FormButton
                        disable={disabled}
                        onClick={() => {
                            checkout();
                        }}>
                        {label()}
                    </FormButton>
                </div>
            }
            <CartMinOrderChargeDialog />
        </>
    )
}
