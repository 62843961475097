import * as styled from "./DeliveryTypeScreen.styled";
import * as StyledTheme from "../../../theme/styled-components/Theme.styled";
import {Footer} from "../../../theme/copmponents/Footer";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import React, {useEffect} from "react";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronRight} from "react-icons/bs";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {CartType} from "../../cartInterfaces";
import {setCartType, setDeliveryCost, setDeliveryMinOrder} from "../../cartSlice";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../intl/translator";
import {selectVendor} from "../../../vendor/vendorSlice";
import {isDeliveryAvailable, isTakeAwayAvailable} from "../../cartService";
import CONFIG from "../../../../app/config";
import {selectUser, setUserCompletedOrders} from "../../../user/userSlice";
import {UserStatus} from "../../../user/userInterfaces";
export function DeliveryTypeScreen() {
    const strings = {
        's_delivery': Translate('delivery'),
        's_take_away': Translate('take_away'),
        's_title': Translate('order_type_screen_title')
    }
    const divStyle = "";
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const vendor = useAppSelector(selectVendor);
    const user = useAppSelector(selectUser);
    useEffect(() => {
        window.history.pushState({}, 'item')
        const handleBack = (e: PopStateEvent) => {
            e.stopPropagation();
        }
        window.addEventListener('popstate', handleBack);
        return(() => {
            window.removeEventListener('popstate', handleBack);
        })

    },[])

    const submitCartType = (type: CartType) => {
        dispatch(setCartType(type));
        if(type === CartType.Delivery){
            dispatch(setDeliveryMinOrder(parseFloat(vendor.DeliveryMinCost ?? '0')));
            if(user && user.RegisteredUserID !== CONFIG.GUEST_USER_ID && user.Verified === "1"){
                navigate('/address/list');
            }else{
                navigate('/login')
            }
           
        }else{
            dispatch(setDeliveryMinOrder(0));
            dispatch(setDeliveryCost(0));
            navigate('/menu');
        }
    }
  
    const isConditionMetDelivery = vendor.Delivery == "0" || vendor.OperationID !== "3"  ||  !isDeliveryAvailable(vendor) ;
    const cardPanelStyleDelivery: React.CSSProperties = {
      pointerEvents: isConditionMetDelivery ? 'none' : 'auto',
      opacity: vendor.EnableDeliveryMenu != "1" ? 0.5 : 1,
      cursor: isConditionMetDelivery  ? 'not-allowed' : 'pointer',
      backgroundColor: isConditionMetDelivery  ? 'gray' : "white",
      display: isConditionMetDelivery  && vendor.EnableDeliveryMenu == "1"  ? 'none' : "block"
      // Add more styles as needed
    };

    const isConditionMetTakeAway = vendor.Delivery == "0" || vendor.OperationID !== "3"  ||  !isTakeAwayAvailable(vendor);
    const cardPanelStyleTakeAway: React.CSSProperties = {
        pointerEvents: isConditionMetTakeAway ? 'none' : 'auto',
        opacity: vendor.EnableDeliveryMenu != "1" ? 0.5 : 1,
        cursor: isConditionMetTakeAway  ? 'not-allowed' : 'pointer',
        backgroundColor: isConditionMetTakeAway  ? 'gray' : "white",
        display: isConditionMetTakeAway  && vendor.EnableDeliveryMenu == "1" ? 'none' : "block"
      // Add more styles as needed
    };

    // console.log("test "+ vendor.EnableDeliveryMenu)
    // console.log("isConditionMetTakeAway "+ isConditionMetTakeAway)
    // console.log("isConditionMetDelivery "+ isConditionMetDelivery)
    const isConditionMenu = (isConditionMetTakeAway || isConditionMetDelivery) && vendor.EnableDeliveryMenu == "1" ;
    
    const cardPanelStyleMenu: React.CSSProperties = {
    
        display: !isConditionMenu   ? 'none' : "block",
        width: "300px",
        margin: "auto"

      // Add more styles as needed
    };
    

    const deliveryTextWithLineBreaks = vendor.DeliveryText?  vendor.DeliveryText.replace(/\n/g, '<br>') : " ";
    const deliveryClosed = vendor.CloseDeliveryMsg?  vendor.CloseDeliveryMsg.replace(/\n/g, '<br>') : " ";
    return (
        
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage/>
            <Footer negative="-60vh">
                <>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card}/>
                    </div>
                    
                    {/* <h1>delivery {isDeliveryAvailable(vendor)? "true": "false"}-s-</h1>
                    <h1>open {vendor.IsOpen == "1"? "true": "false"}</h1>
                    <h1>operation {vendor.OperationID != "3" ? "true": "false"}</h1>
                    <h1>operation {vendor.OperationID}</h1>
                    <h1>open { vendor.IsOpen}</h1> */}
                    

                    <styled.DeliveryTypeScreen className="pt-36 px-8 pb-12 md:px-6">
                    {(vendor.Delivery == "0" || vendor.OperationID != "3"  || !isDeliveryAvailable(vendor)) &&
                    <h1 style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: deliveryClosed }}> 
                    
                    </h1>
                  
                    }
                  
                    <h1 dangerouslySetInnerHTML={{ __html: deliveryTextWithLineBreaks}}>
                    
                   
                        </h1>
                        <div className="options" style={{
                 
                }}>
                           
                           
                            
                            <div className='card-panel' onClick={() => submitCartType(CartType.Delivery)} style={cardPanelStyleDelivery}>
                                <div className="image" >
                                    <img src="/images/delivery_options/delivery.png" alt="delivery"/>
                                </div>
                                <div className="title">
                                    <h3>{strings.s_delivery}</h3>
                                    <ActionButton ><BsChevronRight /></ActionButton>
                                </div>
                            </div>
                            
                            
                            <div style={cardPanelStyleTakeAway} className='card-panel' onClick={() => submitCartType(CartType.TakeAway)}>
                                <div className="image">
                                    <img src="/images/delivery_options/take_away.png" alt="take_away"/>
                                </div>
                                <div className="title">
                                    <h3>{strings.s_take_away}</h3>
                                    <ActionButton ><BsChevronRight /></ActionButton>
                                </div>
                            </div>
                            

                            <a href={`https://web.ninatech.co/${vendor.DeliveryMenuCode}`}  style={cardPanelStyleMenu} >
                                <div  className='card-panel'>
                                    <div className="image">
                                        <img src="/images/delivery_options/menu-icon.png" alt="take_away" style={{ height: '80%', marginTop: '10%' }} />
                                    </div>
                                    <div className="title">
                                        <h3>Menu</h3>
                                        <ActionButton ><BsChevronRight /></ActionButton>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </styled.DeliveryTypeScreen>
                </>
            </Footer>
        </div>
    )
}
