import * as StyledTheme from "../../../theme/styled-components/Theme.styled";
import {Footer} from "../../../theme/copmponents/Footer";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import {Translate} from "../../../intl/translator";
import {
    selectTable,
    selectVendor,
    selectVendorPaymentMethods,
    setVendor,
    setVendorSchedule
} from "../../../vendor/vendorSlice";
import {selectLocale} from "../../../intl/intlSlice";
import {selectCart} from "../../cartSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectUser} from "../../../user/userSlice";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Order} from "../../Order";
import CONFIG from "../../../../app/config";
import {CartType, PaymentTypes} from "../../cartInterfaces";
import {VendorPaymentMethodI} from "../../../vendor/vendorInterfaces";
import {UserStatus} from "../../../user/userInterfaces";
import {createOrder} from "../../cartRepository";
import {addOrderToPastOrders, PastOrderI} from "../../ordersSlice";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronLeft} from "react-icons/bs";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {canGuestUserOrder} from "../../../menu/menuService";
import {getVendor, getVendorSchedule} from "../../../vendor/vendorRepository";
import {CheckoutMethod} from "./CheckoutMethod";
import {RoomChargeBottomSheet, RoomChargeBottomSheetState} from "./RoomChargeBottomSheet";
import {CheckoutDetails} from "./CheckoutDetails";
import {JccHandler} from "./gateways/Jcc/JccHandler";
import {VivaHandler} from "./gateways/VivaWallet/VivaHandler";
import {SaferPayHandler} from "./gateways/SaferPay/SaferPayHandler";
import {isDeliveryAvailable} from "../../cartService";
import {Cart} from "../../Cart";
import {WalleeHandler} from "./gateways/Wallee/WalleeHandler";
import {JccApiHandler} from "./gateways/JccApi/JccApiHandler";
import { VendorDetails } from "../../../vendor/components/VendorDetails";

export function CheckoutScreen() {
    const strings = {
        s_title: Translate('checkout'),
        s_description: Translate('checkout_screen_description'),
        s_back: Translate('button_back_to_menu')
    }
    const vendor = useAppSelector(selectVendor);
    const cart = useAppSelector(selectCart);
    const table = useAppSelector(selectTable);
    const user = useAppSelector(selectUser);
    const paymentMethods = useAppSelector(selectVendorPaymentMethods);
    const activeLocale = useAppSelector(selectLocale);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [shopOpen, setShopOpen] = useState(true);
    const [roomChargeSheet, setRoomChargeSheet] = useState<RoomChargeBottomSheetState>({open: false});




    useEffect(() => {
        if (cart.items.length === 0 ||
            (user.Verified !== UserStatus.Verified && !canGuestUserOrder({vendor}))
        ) {
            navigate("/login");
        } else {

            Promise.all([
                getVendor(vendor.OutletID),
                getVendorSchedule(vendor.OutletID)
            ]).then(res => {
                dispatch(setVendor(res[0]));
                dispatch(setVendorSchedule(res[1]));
                setShopOpen(isDeliveryAvailable(vendor));
                setLoading(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (cart.type === CartType.Delivery && !isDeliveryAvailable(vendor)){
            navigate("/delivery/type");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cart, vendor])

    const onCheckout = (paymentType: VendorPaymentMethodI) => {
        setLoading(true);
        let order: Order = new Order(cart);
        order.info.Tableid = parseInt(table?.TableID ?? "1");
        order.info.Outletid = parseInt(vendor.OutletID);
        order.info.Reguserid = user.Verified !== UserStatus.Verified ? CONFIG.GUEST_USER_ID : user.RegisteredUserID;
        order.info.Ptypeid = parseInt(paymentType.PaymentTypeID);
        order.info.OrderType = cart.type ?? CartType.TakeAway;
        order.info.Locationid = user.Address?.LocationID;

        if (cart.type === CartType.Delivery){
            let CartM = new Cart(cart);
            order.minOrderFee = CartM.calcDeliveryMinOrderFee() ?? 0;
        }

        if(user.FirebaseToken){
            order.info.FirebaseToken = user.FirebaseToken;
        }


        let gatewayData = {paymentType, order, navigate, activeLocale, dispatch, vendor, cart, user}

        switch (paymentType.PaymentTypeID) {
            case PaymentTypes.JCC: {
                let gateway = new JccHandler(gatewayData);
                gateway.submit();
                break;
            }
            case PaymentTypes.JCC_API: {
                let gateway = new JccApiHandler(gatewayData);
                gateway.submit();
                break;
            }
            case PaymentTypes.VIVA_WALLET: {
                let gateway = new VivaHandler(gatewayData);
                gateway.submit();
                break;
            }
            case PaymentTypes.SAFER_PAY: {
                let gateway = new SaferPayHandler(gatewayData);
                gateway.submit();
                break;
            }
            case PaymentTypes.WALLEE: {
                let gateway = new WalleeHandler(gatewayData);
                gateway.submit();
                break;
            }
            case PaymentTypes.ROOM_CHARGE: {
                setRoomChargeSheet({open: true, order});
                break;
            }
            case PaymentTypes.HOUSE_ACCOUNT: {
                // setAccountChargeSheet({open: true, order});
                break;
            }
            default: {
                submitOrder(order);
                break;
            }
        }

    }

    const submitOrder = (order: Order) => {
        createOrder(order, activeLocale.LanguageID).then((res) => {
            const pastOrder: PastOrderI = {
                VendorId: vendor.OutletID,
                OrderId: res.OrderID,
                Cart: cart
            }
            console.log("check Order id: "+res.OrderID)
            dispatch(addOrderToPastOrders(pastOrder))
            document.location.replace("/order");
        }).catch(e => navigate('/order/failed'))
    }

    const close = () => {
        navigate('/menu');
    }

    return (
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage>
                <div className="actions indent  py-8">
                    <div className="flex items-center">
                        <div className="flex items-center cursor-pointer" onClick={close}>
                            <ActionButton>
                                <BsChevronLeft size={24}/>
                            </ActionButton>
                            <div className="ml-15">{strings.s_back}</div>
                        </div>

                    </div>
                </div>
            </StyledTheme.SizedContentPage>
            <Footer>
                <div>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card} />
                    </div>
                    <div className='card-panel'>
                        <div style={{background: "white"}} className="pt-36 px-4 pb-12 md:px-20">
                            <h1 className='text-3xl'>{strings.s_title}</h1>
                            {!loading && shopOpen &&
                                <>
                                    <CheckoutDetails />
                                    <p className='mt-10 mb-8 md:mb-8'>
                                      {strings.s_description}:
                                    </p>
                                </>

                            }
                            {loading &&
                                <div className="h-96 h-px flex justify-center items-center pt-16 pb-12">
                                    <AiOutlineLoading3Quarters size={50} className="animate-spin m-auto" color="var(--theme-primary-color)"/>
                                </div>
                            }
                            {!loading && shopOpen &&
                                <div className="mt-6 indent">
                                    {paymentMethods.map((method: VendorPaymentMethodI) => {
                                        return <CheckoutMethod key={method.PaymentTypeID} method={method} action={onCheckout}/>
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Footer>
            <RoomChargeBottomSheet
                sheetState={roomChargeSheet}
                setSheetState={setRoomChargeSheet}
                setLoading={setLoading}
                submitOrder={submitOrder}
            />
        </div>
    )
}
