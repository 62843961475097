import {VendorI, VendorPaymentMethodI} from "../vendor/vendorInterfaces";

export function getPaymentIconById(paymentTypeId: string): string {
    let icons = {
        "1" : "/images/payment/paypal.png",
        "2" : "/images/payment/credit_card.png",
        "4" : "/images/payment/credit_card.png",
        "5" : "/images/payment/Cash_icon.png",
        "6" : "/images/payment/room_charge.png",
        "7" : "/images/payment/apple_pay.png",
        "8" : "/images/payment/credit_card.png",
        "9" : "/images/payment/credit_card.png",
        "10" : "/images/payment/credit_card.png",
        "28" : "/images/payment/credit_card.png",
    };
    if(paymentTypeId in icons){
        // @ts-ignore
        return icons[paymentTypeId]
    }else{
        return "/images/payment/Cash_icon.png"
    }
}


export function getOrderStatusColor(status: string):string {
    let colors = {
        "New": "orange",
        "In Process": "blue",
        "Completed": "green",
        "Canceled": "red",
    }
    if(status in colors){
        // @ts-ignore
        return colors[status]
    }else{
        return ""
    }

}

export function getPaymentTypeById(methods: Array<VendorPaymentMethodI>, methodId: string): VendorPaymentMethodI | undefined {
    return methods.find(m => m.PaymentTypeID === methodId);
}

export function isTakeAwayAvailable(vendor: VendorI): boolean {
    // console.log("isDeliveryAvailable: ");
    let openTime = vendor.TakeAwayOpeningTime;
    let closeTime = vendor.TakeAwayClosingTime;
    let currentDate = new Date();
    // console.log("take away: "+ vendor.Delivery);
    // console.log("delivery: "+ vendor.TakeAway);
    
    if(!openTime || !closeTime){
        return false;
    }
    let openDate = new Date(currentDate.getTime());
    openDate.setHours(parseInt(openTime.split(":")[0]));
    openDate.setMinutes(parseInt(openTime.split(":")[1]));
    openDate.setSeconds(parseInt(openTime.split(":")[2]));

    let closeDate = new Date(currentDate.getTime());
    closeDate.setHours(parseInt(closeTime.split(":")[0]));
    closeDate.setMinutes(parseInt(closeTime.split(":")[1]));
    closeDate.setSeconds(parseInt(closeTime.split(":")[2]));

    // console.log("OpeningTime: "+ vendor.DeliveryOpeningTime);
    // console.log("closeTime: "+ vendor.DeliveryClosingTime);
    // console.log("currentDate: "+ currentDate.getTime());
    // console.log("test: "+  (openDate < currentDate && closeDate > currentDate));
   
    return (openDate < currentDate && closeDate > currentDate);
}
export function isDeliveryAvailable(vendor: VendorI): boolean {
    // console.log("isDeliveryAvailable: ");
    let openTime = vendor.DeliveryOpeningTime;
    let closeTime = vendor.DeliveryClosingTime;
    let currentDate = new Date();
    // console.log("take away: "+ vendor.Delivery);
    // console.log("delivery: "+ vendor.TakeAway);
    
    if(!openTime || !closeTime){
        return false;
    }
    let openDate = new Date(currentDate.getTime());
    openDate.setHours(parseInt(openTime.split(":")[0]));
    openDate.setMinutes(parseInt(openTime.split(":")[1]));
    openDate.setSeconds(parseInt(openTime.split(":")[2]));

    let closeDate = new Date(currentDate.getTime());
    closeDate.setHours(parseInt(closeTime.split(":")[0]));
    closeDate.setMinutes(parseInt(closeTime.split(":")[1]));
    closeDate.setSeconds(parseInt(closeTime.split(":")[2]));

    // console.log("OpeningTime: "+ vendor.DeliveryOpeningTime);
    // console.log("closeTime: "+ vendor.DeliveryClosingTime);
    // console.log("currentDate: "+ currentDate.getTime());
    // console.log("test: "+  (openDate < currentDate && closeDate > currentDate));
   
    return (openDate < currentDate && closeDate > currentDate);
}
