export interface VendorI {
    OutletID: string,
    Name: string,
    EstablishmentName?: string,
    TypeName?: string,
    CurrencySymbol?: string,
    OutleTypeID?: number,
    EstablismentID?: number,
    CurrencyID?: number,
    SubscriptionPlanID?: number,
    IsActive?: number,
    Description?: string,
    Address?: string,
    Country?: string,
    City?: string,
    PostalCode?: string,
    AcceptedOrdersTimeLimit?: string,
    OrdersDelayTime?: string,
    Email?: string,
    PhoneNumber?: string,
    FaxNumber?: string,
    URL?: string,
    FileName?: string,
    CoverImage?: string,
    GoogleLongitudeCoord?: string,
    GoogleLatitudeCoord?: string,
    Logo?: string,
    IsSecretKeyNeeded?: string,
    HasRooms?: number,
    SecretKey?: string,
    Disabled?: string,
    DeliveryText?: string,
    CloseDeliveryMsg?: string,
    IsOpen?: string,
    Is24hOpen?: string,
    AllowGuestUsers?: string,
    OrderSystemFlag?: number,
    EnrollmentDate?: string,
    SubscriptionDate?: string,
    SubscriptionEndDate?: string,
    OpeningTime?: string,
    ClosingTime?: string,
    ClosingSoonTime?: string,
    OpeningTimestamp?: string,
    DisableOrders?: number,
    PriceDisabled?: number,
    OrderSystem?: number,
    OperationID?: string,
    VisibilityOption?: string,
    AlohaStoreID?: string,
    HasSchedule?: number,
    HasSendToKitchen?: number,
    SeperateChecks?: number,
    outletFileName?: string,
    Color?: string,
    SplashImage?: string,
    DeliveryMinCost?: string,
    DeliveryOpeningTime?: string,
    DeliveryClosingTime?: string,
    TakeAwayOpeningTime?: string,
    TakeAwayClosingTime?: string,
    OpenStatus?: VendorOpenStatusI,
    Delivery? : string,
    TakeAway? : string,
    DeliveryMinPopUp?: string
    DeliveryMenuCode?: string
    EnableDeliveryMenu?:string
}

export interface VendorButtonI {
    ItemID: string,
    Name: string
}

export interface VendorScheduleI {
    working_hours: string,
    Service_hours: string,
    ClosingSoon: string
}

export interface QrDetailsI {
    QrID: string,
    ReferenceId: string,
    OutletID: string,
    TableID: string,
    GroupQR: string,
    SecretKey: string,
}

export interface QrOutletI {
    OutletID: string,
    TypeName: string,
    CoverImage: string,
    Name: string
}

export interface EstablishmentI {
    Address: string,
    City: string,
    Country: string,
    CoverImage: string,
    Description: string,
    EstablismentID: string,
    FaxNumber: string,
    Name: string,
    OutletID: string,
    PhoneNumber:string,
    TypeName: string,
    URL: string,
    LogoFileName: string,
    TableID: string,
    Outlets: Array<QrOutletI>
}

export interface EstablishmentCategoryI {
    name: string,
    Outlets: Array<QrOutletI>
}

export interface TableInfoI {
    TableID: string,
    TableTypeID: string,
    TableNo: string,
    TableName?: string,
    NumOfSeats?: string,
    TypeName: string,
}

export interface ShopOpenStatusI {
    Color: string,
    Description: string
}

export interface ShopMapUrlI {
    isSet: boolean,
    url: string
}

export interface  VendorLoadDataI {
    id: string,
    table: string
}

export interface VendorPaymentMethodI {
    OutletID: string,
    PaymentTypeID: string,
    AccountID?: string,
    SortOrder?: string,
    Disable?: string,
    SendEmailToUsers?: string,
    HasPromptMessage?: string,
    PromptMessage?: string,
    PaymentTypeName?: string
    OrderType: string
}

export interface VendorCompanyI {
    CompanyOutletID: string,
    OutletID: string,
    CompanyID: string,
    Name: string,

}

export interface VendorOpenStatusI {
    ClosingSoon: boolean,
    DeliveryStatus: boolean,
    Status: boolean,
}

export enum VendorVisibility {
    OutletInformationOnly = "1",
    MenuNoPrices = "2",
    MenuWithPrices = "3",
    Hidden = "5"
}


export enum VendorOperation {
    Disable = "1",
    MenuOnly = "2",
    DigitalOrdering = "3"
}
